@use "../styles/utils.module";

.subtitle {
  margin-top: 0px;
  color: #fafafa;
}

.parrot {
  position: absolute;
  top: 30px;
  left: calc(50% - 60px);
  width: 120px;
}

.landingHeader {
  display: flex;
  flex-direction: column;
  background-color: utils.$bluePurple;
  background: rgb(113, 76, 253);
  background: radial-gradient(circle, rgba(113, 76, 253, 1) 0%, rgba(104, 165, 238, 1) 100%);
  min-height: calc(90vh);
  max-height: 800px;
  justify-content: space-evenly;
  padding-bottom: 30px;

  .left {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 30px 10px 20px;
    justify-content: flex-start;
    align-items: center;

    .headerImage {
      padding-top: 80px;
      width: 80%;
      max-width: 500px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: center;
    padding: 20px 20px 30px;
    text-align: center;
  }
}

.downloadGap {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.benefits {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: "#fafafa";

  .benefitItem {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 15px;
    align-items: center;
    justify-content: center;

    .benefitItemImage {
      padding: 15px;
    }

    .benefitItemText {
      padding-left: 15px;
      text-align: left;
    }
  }
}

@media only screen and (min-width: utils.$screenM) {
  .landingHeader {
    justify-content: center;

    .left {
      width: 100%;
      align-items: center;
      padding: 60px 15px 40px 15px;

      .headerImage {
        max-height: 460px;
        padding: 40px 0 0 0px;
        width: 85%;
      }
    }

    .right {
    }
  }

  .benefits {
    .benefitItem {
      padding: 15px 30px;
    }
  }

  .downloadGap {
    margin-top: 30px;
  }
}

@media only screen and (min-width: utils.$screenL) {
  .parrot {
    position: absolute;
    top: 20px;
    left: 30px;
  }

  .landingHeader {
    min-height: calc(81vh);
    justify-content: flex-start;
    flex-direction: row;
    padding: 0 10%;

    .left {
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      flex-basis: 40%;

      .headerImage {
        padding-top: 0px;
        width: 90%;
        max-width: 100%;
        max-height: 800px;
        padding: 0;
      }
    }

    .right {
      justify-content: center;
      flex-grow: 3;
      flex-basis: 60%;
    }
  }

  .benefits {
    flex-direction: row;

    .benefitItem {
      .benefitItemImage {
      }

      .benefitItemText {
      }
    }
  }
}

@media only screen and (min-width: utils.$screenXL) {
  .landingHeader {
    padding: 0 10%;

    .left {
      align-items: flex-end;
    }

    .right {
      flex-basis: 75%;
    }
  }
}

@media only screen and (min-width: utils.$screenXXL) {
  .landingHeader {
    padding: 0 12%;

    .left {
    }

    .right {
    }
  }
}

.recommendation {
  height: 60px;
  margin-right: 15px;
  margin-bottom: 10px;

  @media only screen and (min-width: utils.$screenMinDesktop) {
    height: 80px;
    margin-bottom: 0px;
  }
}
